import React, { useState } from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Modal } from "react-responsive-modal";
import { Link } from 'react-router-dom';
import Amzadkhan from "../../Assets/team/Amzad_khan.png"
import manojsingh from "../../Assets/team/Manoj_singh.png"
import sulakshana from "../../Assets/team/Sulakshana Padhi.png"
import "react-responsive-modal/styles.css";
import "../Stylesheet/style.css"

const Teamsection = () => {
    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false);

    return (
        <div class="w-full min-h-screen bg-gray-100 py-8 px-[10px] md:px-10 pt-10 flex flex-col items-center justify-center">
            {/* amzad khan */}
            <div class="relative mt-14 mb-20 md:w-[80%]">
                <div class="rounded overflow-hidden shadow-md bg-white">
                    <div class="absolute -mt-20 w-full flex justify-center">
                        <div class="h-32 w-32">
                            {/* <img src={`${process.env.PUBLIC_URL}/public/Amzad_khan.png`} class="rounded-full object-cover h-full w-full shadow-md" /> */}
                            <img src={Amzadkhan} class="rounded-full object-cover h-full w-full shadow-md" />
                        </div>
                    </div>
                    <div class="px-6 mt-16">
                        <h1 class="font-bold text-3xl text-center mb-1">Amjad khan</h1>
                        <p class="text-gray-800 text-sm text-center">Director</p>
                        <p class="text-center text-gray-600 text-sm pt-3 font-normal">
                            With more than 20 years of experience in livelihoods, capacity building, micro-finance, and strengthening people institutions, he has worked with notable organizations like PRADAN and ACCESS Development Services and currently consults for organizations such as UNDP, TRIF, and TRLM. As a freelance consultant, he has supported a wide range of non-profits, CSR initiatives, and state governments such as UNDP, ICCO, PACS, SDTT, PRADAN, GVT, NSPDT, MPWPCL, DigitalGreen, Centre for Microfinance, Reliance Foundation, L&T, and SRLMs (Tripura, Meghalaya, Manipur, Arunachal, Sikkim, Mizoram, Madhya Pradesh, Uttar Pradesh). His expertise spans livelihoods, behavior change, institution strengthening, organization development, and innovative development processes, making significant contributions to empowering communities and promoting sustainable rural development.
                        </p>
                        <div class="w-full flex justify-center pt-5 pb-5">
                            <a href="https://in.linkedin.com/in/amjad-khan-b264b2a" class="mx-5">
                                <div aria-label="Linkedin">
                                    <svg width="24" height="24" fill='#718096' viewBox="0 0 0.72 0.72" data-name="Layer 1"><path d="M.525.27a.2.2 0 0 0-.077.019A.03.03 0 0 0 .42.27H.3A.03.03 0 0 0 .27.3v.36A.03.03 0 0 0 .3.69h.12A.03.03 0 0 0 .45.66V.495a.03.03 0 1 1 .06 0V.66a.03.03 0 0 0 .03.03h.12A.03.03 0 0 0 .69.66V.435A.165.165 0 0 0 .525.27M.63.63H.57V.495a.09.09 0 1 0-.18 0V.63H.33v-.3h.06v.021A.03.03 0 0 0 .443.37.104.104 0 0 1 .63.435ZM.21.27H.09A.03.03 0 0 0 .06.3v.36a.03.03 0 0 0 .03.03h.12A.03.03 0 0 0 .24.66V.3A.03.03 0 0 0 .21.27M.18.63H.12v-.3h.06ZM.15.046A.097.097 0 1 0 .148.24h.001A.097.097 0 1 0 .15.046M.149.18H.148A.037.037 0 0 1 .107.143C.107.121.124.106.15.106a.037.037 0 0 1 .042.037C.192.165.175.18.149.18" /></svg>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* manoj singh */}
            <div class="relative mt-10 mb-20 md:w-[80%]">
                <div class="rounded overflow-hidden shadow-md bg-white">
                    <div class="absolute -mt-20 w-full flex justify-center">
                        <div class="h-32 w-32">
                            {/* <img src={`${process.env.PUBLIC_URL}/public/Manoj_singh.png`} class="rounded-full object-cover h-full w-full shadow-md" /> */}
                            <img src={manojsingh} class="rounded-full object-cover h-full w-full shadow-md" />
                        </div>
                    </div>
                    <div class="px-6 mt-16">
                        <h1 class="font-bold text-3xl text-center mb-1">Manoj singh</h1>
                        <p class="text-gray-800 text-sm text-center">Director</p>
                        <p class="text-center text-gray-600 text-sm pt-3 font-normal">
                            With more than 12 years of experience in rural development sector, he has extensive experience on capacity building and leadership promotion in community-based institutions focused on sustainable livelihoods, local governance, and natural resource management. He has consulted for organizations such as TRIF, PRADAN, KPS, PRAXIS India, and SST. Manoj has worked in Madhya Pradesh, West Bengal, and Uttar Pradesh, and played a key role in establishing Kanhar Kheda, a forest farm in Balaghat, MP. As a freelance consultant, he has contributed to facilitating grassroots projects, developing action plans, documenting impact assessments, and promoting climate resilience and livelihood initiatives.
                        </p>
                        <div class="w-full flex justify-center pt-5 pb-5">
                            <a href="www.linkedin.com/in/manoj-singh-65759246" class="mx-5">
                                <div aria-label="Linkedin">
                                    <svg width="24" height="24" fill='#718096' viewBox="0 0 0.72 0.72" data-name="Layer 1"><path d="M.525.27a.2.2 0 0 0-.077.019A.03.03 0 0 0 .42.27H.3A.03.03 0 0 0 .27.3v.36A.03.03 0 0 0 .3.69h.12A.03.03 0 0 0 .45.66V.495a.03.03 0 1 1 .06 0V.66a.03.03 0 0 0 .03.03h.12A.03.03 0 0 0 .69.66V.435A.165.165 0 0 0 .525.27M.63.63H.57V.495a.09.09 0 1 0-.18 0V.63H.33v-.3h.06v.021A.03.03 0 0 0 .443.37.104.104 0 0 1 .63.435ZM.21.27H.09A.03.03 0 0 0 .06.3v.36a.03.03 0 0 0 .03.03h.12A.03.03 0 0 0 .24.66V.3A.03.03 0 0 0 .21.27M.18.63H.12v-.3h.06ZM.15.046A.097.097 0 1 0 .148.24h.001A.097.097 0 1 0 .15.046M.149.18H.148A.037.037 0 0 1 .107.143C.107.121.124.106.15.106a.037.037 0 0 1 .042.037C.192.165.175.18.149.18" /></svg>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Sulakshana Padhi */}
            <div class="relative mt-10  md:w-[80%]">
                <div class="rounded overflow-hidden shadow-md bg-white">
                    <div class="absolute -mt-20 w-full flex justify-center">
                        <div class="h-32 w-32">
                            {/* <img src={`${process.env.PUBLIC_URL}/Sulakshana Padhi.png`} class="rounded-full object-cover h-full w-full shadow-md" /> */}
                            <img src={sulakshana} class="rounded-full object-cover h-full w-full shadow-md" />
                        </div>
                    </div>
                    <div class="px-6 mt-16">
                        <h1 class="font-bold text-3xl text-center mb-1">Sulakshana Padhi</h1>
                        <p class="text-gray-800 text-sm text-center">Senior Consultant</p>
                        <p class="text-center text-gray-600 text-sm pt-3 font-normal">
                            With over 20 years of experience in rural development and poverty eradication, she specializes in training design, organizational process development, and capacity building for staff and CBOs. As a National Resource Person for NRLM, she has supported SRLMs across India in social mobilization and institution building. With a background in PRADAN, she is an expert in livelihood promotion, institutional strengthening, and gender empowerment. She has played a key role in driving transformative change in rural communities and is a representative in the "Transforming Rural India Foundation" (TRIF) for Brainstem.
                        </p>
                        <div class="w-full flex justify-center pt-5 pb-5">
                            <a href="https://www.linkedin.com/in/sulakshana-padhi-3a6863167" class="mx-5">
                                <div aria-label="Linkedin">
                                    <svg width="24" height="24" fill='#718096' viewBox="0 0 0.72 0.72" data-name="Layer 1"><path d="M.525.27a.2.2 0 0 0-.077.019A.03.03 0 0 0 .42.27H.3A.03.03 0 0 0 .27.3v.36A.03.03 0 0 0 .3.69h.12A.03.03 0 0 0 .45.66V.495a.03.03 0 1 1 .06 0V.66a.03.03 0 0 0 .03.03h.12A.03.03 0 0 0 .69.66V.435A.165.165 0 0 0 .525.27M.63.63H.57V.495a.09.09 0 1 0-.18 0V.63H.33v-.3h.06v.021A.03.03 0 0 0 .443.37.104.104 0 0 1 .63.435ZM.21.27H.09A.03.03 0 0 0 .06.3v.36a.03.03 0 0 0 .03.03h.12A.03.03 0 0 0 .24.66V.3A.03.03 0 0 0 .21.27M.18.63H.12v-.3h.06ZM.15.046A.097.097 0 1 0 .148.24h.001A.097.097 0 1 0 .15.046M.149.18H.148A.037.037 0 0 1 .107.143C.107.121.124.106.15.106a.037.037 0 0 1 .042.037C.192.165.175.18.149.18" /></svg>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Teamsection