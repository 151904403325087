import React, { useRef } from 'react'

const Legal = () => {
    const PrivacyRef = useRef(null);
    const ConditionsRef = useRef(null);

    // value === "privacy" && PrivacyRef.current?.scrollIntoView({ behavior: 'smooth' });

    const handleClick = (value) => {
        if (value === "privacy") {
            PrivacyRef.current.style.display = "flex"
            ConditionsRef.current.style.display = "none"
        }
        if (value === "condition") {
            PrivacyRef.current.style.display = "none"
            ConditionsRef.current.style.display = "flex"
        }
    }
    return (
        <div className='relative'>
            <h4 className='text-5xl text-center py-[3%]'>Legal</h4>
            <div className=''>
                <div className='Font-Aviano text-sm flex flex-wrap gap-3 justify-center p-8'>
                    <div onClick={() => handleClick("privacy")}>
                        <input type="radio" id="privacy" name="legal" value="" className='peer hidden' defaultChecked />
                        <label htmlFor="privacy" className='text-gray-400 peer-checked:text-black cursor-pointer'>PRIVACY POLICY</label><br />
                    </div>
                    <span>|</span>
                    <div onClick={() => handleClick("condition")}>
                        <input type="radio" id="condition" name="legal" value="" className='peer hidden' />
                        <label htmlFor="condition" className='text-gray-400 peer-checked:text-black cursor-pointer'>CONDITIONS OF USE</label><br />
                    </div>
                </div>
                <div className=''>
                    <div ref={PrivacyRef} id='priDiv' className='flex justify-center'>
                        <div className='w-[65%] border-t xl:flex lg:py-[5%]  '>
                            <div className='lg:w-[30%] py-4 relative'>
                                <div className='lg:sticky lg:top-[20%] w-full text-3xl'>Privacy Policy
                                    <h6 className='text-base'>Last updated on 22nd June 2018</h6>
                                </div>
                            </div>
                            <div className='h-full w-full'>
                                <p className='p-4'>
                                    <h2 className='text-2xl'>Information We Collect</h2>
                                    <br />

                                    When you visit our website, we may collect certain information about you, including:

                                    Personal information such as your name, email address, and contact details, which you provide voluntarily when subscribing to our newsletter or contacting us through our website.

                                    Non-personal information such as your IP address, browser type, and device information, collected automatically through cookies and other tracking technologies.
                                    <br /><br />
                                    <h2 className='text-2xl'>How We Use Your Information</h2>
                                    <br />
                                    We may use the information we collect from you for the following purposes:

                                    To personalize your experience and provide you with the services and information you request.
                                    To improve our website and enhance user experience.
                                    To send periodic emails and newsletters containing updates, promotions, and other relevant information. You may opt out of receiving these communications at any time by following the unsubscribe instructions provided in the email.
                                    <br /><br />
                                    <h2 className='text-2xl'>Data Security</h2>
                                    <br />
                                    We take the security of your personal information seriously and implement appropriate measures to protect it from unauthorized access, disclosure, alteration, or destruction.
                                    <br /><br />
                                    <h2 className='text-2xl'>Third-Party Disclosure</h2>
                                    <br />
                                    We do not sell, trade, or otherwise transfer your personal information to third parties without your consent. However, we may share your information with trusted third-party service providers who assist us in operating our website, conducting business, or servicing you.
                                    <br /><br />
                                    <h2 className='text-2xl'>Links to Third-Party Websites</h2>
                                    <br />
                                    Our website may contain links to third-party websites for your convenience and information. These websites have their own privacy policies, which you should review when visiting them. We are not responsible for the privacy practices or content of third-party websites.
                                    <br /><br />
                                    <h2 className='text-2xl'>Changes to This Privacy Policy</h2>
                                    <br />
                                    We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting on this page. We encourage you to review this Privacy Policy periodically for any updates.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div ref={ConditionsRef} id='conDiv' className='hidden justify-center'>
                        <div className='w-[65%] border-t lg:flex py-[5%]'>
                            <div className='lg:w-[30%] py-4 relative'>
                                <div className='lg:sticky lg:top-[20%] w-full text-3xl'>Condition of use
                                    <h6 className='text-base'>Last updated on 22nd June 2018</h6>
                                </div>
                            </div>
                            <div className='h-full w-full'>
                                <p className='p-4'>
                                    <h2 className='text-2xl'>Intellectual Property </h2>
                                    <br />
                                    The Website and its original content, features, and functionality are owned by Brainstem and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
                                    <br /><br /><br />
                                    <h2 className='text-2xl'> Use License</h2>
                                    <br />
                                    Permission is granted to temporarily download one copy of the materials (information or software) on Brainstem's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license, you may not:

                                    modify or copy the materials;
                                    use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
                                    attempt to decompile or reverse engineer any software contained on Brainstem's Website;
                                    remove any copyright or other proprietary notations from the materials; or
                                    transfer the materials to another person or "mirror" the materials on any other server.
                                    This license shall automatically terminate if you violate any of these restrictions and may be terminated by Brainstem at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
                                    <br /><br /><br />
                                    <h2 className='text-2xl'>Limitations</h2>
                                    <br />
                                    In no event shall Brainstem or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Brainstem's Website, even if Brainstem or a Brainstem authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
                                    <br /><br /><br />
                                    <h2 className='text-2xl'>Governing Law</h2>
                                    <br />
                                    These Terms shall be governed and construed in accordance with the laws of [Your Country], without regard to its conflict of law provisions.
                                    <br /><br /><br />
                                    <h2 className='text-2xl'>Changes</h2>
                                    <br />
                                    Brainstem reserves the right, at its sole discretion, to modify or replace these Terms at any time. If a revision is material, Brainstem will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Brainstem's sole discretion.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Legal