import React from 'react'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'

const TermsCondition = () => {
    return (
        <div className='relative'>
            <h2 className='FontCinzel text-center pt-[15%] md:pt-[6%] text-2xl md:text-4xl'>TERMS & CONDITIONS</h2>
            <div className='flex justify-center items-center'><p className='p-4 text-center w-[80%]'>These terms and conditions govern your access to and use of Brainstem website. By accessing or using the Website, you agree to be bound by these Terms. If you disagree with any part of these Terms, you may not access the Website.</p></div>
            <div className='flex justify-center'>
                <div className='w-[80%] py-6 space-y-8 text-justify'>
                    <h2 className='text-2xl'>Intellectual Property </h2>

                    The Website and its original content, features, and functionality are owned by Brainstem and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.

                    <h2 className='text-2xl'> Use License</h2>

                    Permission is granted to temporarily download one copy of the materials (information or software) on Brainstem's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license, you may not:

                    modify or copy the materials;
                    use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
                    attempt to decompile or reverse engineer any software contained on Brainstem's Website;
                    remove any copyright or other proprietary notations from the materials; or
                    transfer the materials to another person or "mirror" the materials on any other server.
                    This license shall automatically terminate if you violate any of these restrictions and may be terminated by Brainstem at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.

                    <h2 className='text-2xl'>Limitations</h2>

                    In no event shall Brainstem or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Brainstem's Website, even if Brainstem or a Brainstem authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.

                    <h2 className='text-2xl'>Governing Law</h2>

                    These Terms shall be governed and construed in accordance with the laws of [Your Country], without regard to its conflict of law provisions.

                    <h2 className='text-2xl'>Changes</h2>

                    Brainstem reserves the right, at its sole discretion, to modify or replace these Terms at any time. If a revision is material, Brainstem will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Brainstem's sole discretion.
                </div>
            </div>
        </div>
    )
}

export default TermsCondition