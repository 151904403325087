import React from 'react'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'

const PrivacyPolicy = () => {
    return (
        <div className='relative'>
            <h2 className='FontCinzel text-center pt-[6%] text-2xl md:text-4xl'>PRIVACY POLICY</h2>
            <div className='flex justify-center items-center'><p className='p-4 text-center w-[80%]'>At Brainstem, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard the information you provide to us when using our website.</p></div>
            <div className='flex justify-center'>
                <div className='w-[80%] py-6 space-y-8 text-justify'>
                    <h2 className='text-2xl'>Information We Collect</h2>

                    When you visit our website, we may collect certain information about you, including:

                    Personal information such as your name, email address, and contact details, which you provide voluntarily when subscribing to our newsletter or contacting us through our website.

                    Non-personal information such as your IP address, browser type, and device information, collected automatically through cookies and other tracking technologies.

                    <h2 className='text-2xl'>How We Use Your Information</h2>

                    We may use the information we collect from you for the following purposes:

                    To personalize your experience and provide you with the services and information you request.
                    To improve our website and enhance user experience.
                    To send periodic emails and newsletters containing updates, promotions, and other relevant information. You may opt out of receiving these communications at any time by following the unsubscribe instructions provided in the email.
                    <h2 className='text-2xl'>Data Security</h2>

                    We take the security of your personal information seriously and implement appropriate measures to protect it from unauthorized access, disclosure, alteration, or destruction.

                    <h2 className='text-2xl'>Third-Party Disclosure</h2>

                    We do not sell, trade, or otherwise transfer your personal information to third parties without your consent. However, we may share your information with trusted third-party service providers who assist us in operating our website, conducting business, or servicing you.

                    <h2 className='text-2xl'>Links to Third-Party Websites</h2>

                    Our website may contain links to third-party websites for your convenience and information. These websites have their own privacy policies, which you should review when visiting them. We are not responsible for the privacy practices or content of third-party websites.

                    <h2 className='text-2xl'>Changes to This Privacy Policy</h2>

                    We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting on this page. We encourage you to review this Privacy Policy periodically for any updates.
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy