import React from 'react'
// import logo from "../../../Assets/BrainstemLogoBG.png"
import logo from "../../../Assets/BrainstemNoB.png"
import { Fade } from 'react-swift-reveal'

const Footer = () => {
    return (
        // <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 border-t cursor-default">
        //     <Fade delay={400}>
        //         <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
        //             <div className="sm:col-span-2">
        //                 <a href="/" aria-label="Go home" title="Company" className="inline-flex items-center">
        //                     <div><img src={logo} alt='' width={30} /></div>
        //                     <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">Brainstem</span>
        //                 </a>
        //                 <div className="mt-6 lg:max-w-sm text-sm ">BRAINSTEM provides capacity building and strategic support to develop enabling eco-systems; comprehensive, customised solutions; and on-site operational assistance to the businesses.</div>
        //             </div>
        //             <div className="space-y-2 text-sm">
        //                 <p className="text-base font-bold tracking-wide text-gray-900">Contacts</p>
        //                 <div className="group flex">
        //                     <p className="mr-1 text-gray-800">Phone:</p>
        //                     <a href="tel:+919424415981" aria-label="Our phone" title="Our phone" className="transition-colors duration-300 text-deep-purple-accent-400 group-hover:text-[#74041c]">&nbsp;&nbsp;+91 9424415981</a>
        //                 </div>
        //                 <div className="group flex">
        //                     <p className="mr-1 text-gray-800">Email:</p>
        //                     <a href="mailto:amjusha@gmail.com" aria-label="Our email" title="Our email" className="transition-colors duration-300 text-deep-purple-accent-400 group-hover:text-[#74041c]">&emsp;&nbsp;amjusha@gmail.com</a>
        //                 </div>
        //                 <div className="group flex">
        //                     <p className="mr-1 text-gray-800">Address:</p>
        //                     <a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer" aria-label="Our address" title="Our address" className="transition-colors duration-300 text-deep-purple-accent-400 group-hover:text-[#74041c]">
        //                     Spectrum Mall, <br />Office no. 21, 6th floor,<br /> Sector 75, Noida - 201301
        //                     </a>
        //                 </div>
        //             </div>
        //             <div>
        //                 <span className="text-base font-bold tracking-wide text-gray-900">Social</span>
        //                 <div className="flex items-center mt-1 space-x-3">
        //                     <a href="/" className="text-gray-500 hover:text-sky-500 transition-colors duration-300 hover:text-deep-purple-accent-400 ">
        //                         <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
        //                             <path
        //                                 d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z"
        //                             ></path>
        //                         </svg>
        //                     </a>
        //                     <a href="/" className="text-gray-500 hover:text-pink-600 transition-colors duration-300 hover:text-deep-purple-accent-400">
        //                         <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
        //                             <circle cx="15" cy="15" r="4"></circle>
        //                             <path
        //                                 d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z"
        //                             ></path>
        //                         </svg>
        //                     </a>
        //                     <a href="/" className="text-gray-500 hover:text-blue-800 transition-colors duration-300 hover:text-deep-purple-accent-400">
        //                         <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
        //                             <path
        //                                 d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z"
        //                             ></path>
        //                         </svg>
        //                     </a>
        //                     <a href="/" className="text-gray-500 hover:text-red-700 transition-colors duration-300 hover:text-deep-purple-accent-400">
        //                         <svg width="24" height="24" viewBox="0 0 0.72 0.72" fill="currentColor"><path d="M.69.291A.26.26 0 0 0 .663.167a.1.1 0 0 0-.052-.03A2 2 0 0 0 .36.128a2 2 0 0 0-.25.009.1.1 0 0 0-.044.022C.039.184.036.227.033.262a1.4 1.4 0 0 0 0 .194.3.3 0 0 0 .009.06.1.1 0 0 0 .021.041.1.1 0 0 0 .045.023 1.4 1.4 0 0 0 .195.01C.408.592.5.59.609.582A.1.1 0 0 0 .655.559a.1.1 0 0 0 .018-.03.3.3 0 0 0 .016-.102zM.292.445V.26L.47.353C.42.381.355.412.292.445" /></svg>
        //                     </a>
        //                 </div>
        //                 <p className="mt-4 text-sm text-gray-500">
        //                 Connect with us on social media to stay updated on the latest news, insights, and events from Brainstem
        //                 </p>
        //             </div>
        //         </div>
        //         <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
        //             <p className="text-sm text-gray-600">
        //                 © Copyright 2024 Brainstem. All rights reserved.
        //             </p>
        //             <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
        //                 <li>
        //                     <a href="/" className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400">F.A.Q</a>
        //                 </li>
        //                 <li>
        //                     <a href="/privacy-policy" className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400">Privacy Policy</a>
        //                 </li>
        //                 <li>
        //                     <a href="/terms-and-conditions" className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400">Terms &amp; Conditions</a>
        //                 </li>
        //             </ul>
        //         </div>
        //     </Fade>
        // </div>
        <div className='font-poppins border-t cursor-default px-[5%] '>
            <Fade>
                <div className="lg:flex justify-around py-[2%]">
                    <div className='lg:w-[30%] min-h-8 pt-4'>
                        <a href="/" aria-label="Go home" title="Brainstem" className="flex items-baseline">
                            <div className='relative flex justify-end items-end'>
                                <img src={logo} alt='' width={30} />
                                <span className="absolute left-[2rem] -bottom-0.5 text-xl font-bold tracking-wide text-gray-800 uppercase">Brain<span className='text-primary1 hover:text-primary2'>stem</span></span>
                            </div>
                        </a>
                        <div className="mt-2 lg:mt-6 lg:max-w-sm text-sm ">BRAINSTEM provides capacity building and strategic support to develop enabling eco-systems; comprehensive, customised solutions; and on-site operational assistance to the businesses.</div>
                    </div>
                    <div className='lg:w-[30%] min-h-8 pt-4'>
                        <p className="text-base font-bold tracking-wide text-gray-900 lg:px-4">Contacts</p>
                        <div class="overflow-x-auto mt-2 lg:mt-6">
                            <table class="min-w-full divide-y-2 divide-gray-200 text-sm">
                                <tbody class="">
                                    <tr>
                                        <td class="whitespace-nowrap lg:px-4 py-2 font-medium text-gray-900">Phone: </td>
                                        <td class="whitespace-nowrap lg:px-4 py-2 text-gray-700 hover:text-primary1">
                                            <a href="tel:+919424415981" aria-label="Our phone" title="Our phone" className="transition-colors duration-300 text-deep-purple-accent-400 group-hover:text-[#74041c]">+91 9424415981</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="whitespace-nowrap lg:px-4 py-2 font-medium text-gray-900">Email: </td>
                                        <td class="whitespace-nowrap lg:px-4 py-2 text-gray-700 hover:text-primary1">
                                            <a href="mailto:amjusha@gmail.com" aria-label="Our email" title="Our email" className="transition-colors duration-300 text-deep-purple-accent-400 group-hover:text-[#74041c]">amjusha@gmail.com</a>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="whitespace-nowrap lg:px-4 py-2 flex justify-start font-medium text-gray-900">Address: </td>
                                        <td class="whitespace-nowrap lg:px-4 py-2 text-gray-700 hover:text-primary1"><a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer" aria-label="Our address" title="Our address" className="transition-colors duration-300 text-deep-purple-accent-400 group-hover:text-[#74041c]">
                                            Spectrum Mall, <br />Office no. 21, 6th floor,<br /> Sector 75, Noida - 201301
                                        </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='lg:w-[30%] min-h-8 pt-4'>
                        <span className="text-base font-bold tracking-wide text-gray-900">Social</span>
                        <div className="flex items-center mt-2 lg:mt-6 space-x-3">
                            <a href="/" className="text-gray-500 hover:text-sky-500 transition-colors duration-300 hover:text-deep-purple-accent-400 ">
                                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                                    <path
                                        d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z"
                                    ></path>
                                </svg>
                            </a>
                            <a href="/" className="text-gray-500 hover:text-pink-600 transition-colors duration-300 hover:text-deep-purple-accent-400">
                                <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                                    <circle cx="15" cy="15" r="4"></circle>
                                    <path
                                        d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z"
                                    ></path>
                                </svg>
                            </a>
                            <a href="/" className="text-gray-500 hover:text-blue-800 transition-colors duration-300 hover:text-deep-purple-accent-400">
                                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                                    <path
                                        d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z"
                                    ></path>
                                </svg>
                            </a>
                            <a href="/" className="text-gray-500 hover:text-red-700 transition-colors duration-300 hover:text-deep-purple-accent-400">
                                <svg width="24" height="24" viewBox="0 0 0.72 0.72" fill="currentColor"><path d="M.69.291A.26.26 0 0 0 .663.167a.1.1 0 0 0-.052-.03A2 2 0 0 0 .36.128a2 2 0 0 0-.25.009.1.1 0 0 0-.044.022C.039.184.036.227.033.262a1.4 1.4 0 0 0 0 .194.3.3 0 0 0 .009.06.1.1 0 0 0 .021.041.1.1 0 0 0 .045.023 1.4 1.4 0 0 0 .195.01C.408.592.5.59.609.582A.1.1 0 0 0 .655.559a.1.1 0 0 0 .018-.03.3.3 0 0 0 .016-.102zM.292.445V.26L.47.353C.42.381.355.412.292.445" /></svg>
                            </a>
                        </div>
                        <p className="mt-4 text-sm text-gray-500">
                            Connect with us on social media to stay updated on the latest news, insights, and events from Brainstem
                        </p>
                    </div>
                </div>
                <div className="flex flex-col-reverse justify-between py-5 px-[2%] border-t lg:flex-row">
                    <p className="text-sm text-gray-600">
                        © Copyright 2024 Brainstem. All rights reserved.
                    </p>
                    <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
                        <li>
                            <a href="/" className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400">F.A.Q</a>
                        </li>
                        <li>
                            <a href="/privacy-policy" className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/terms-and-conditions" className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400">Terms &amp; Conditions</a>
                        </li>
                    </ul>
                </div>
            </Fade>
        </div>
    )
}

export default Footer